<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <b-card>
        <!-- search input -->
        <!-- search input -->
        <div class="custom-search d-flex justify-content-between align-items-end">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search Events"
              type="text"
            />
            <b-button
              :to="{name: 'add-new-events'}"
              variant="primary mr-1"
            >
              <feather-icon icon="PlusIcon" />
              Add Event
            </b-button>
          </b-form-group>
          <b-form-group
            label="View as"
            label-for="btn-radios-1"
            label-size="sm"
          >
            <b-form-radio-group
              id="btn-radios-1"
              v-model="view"
              :options="[
                { text: 'Grid', value: 'grid' },
                { text: 'Table', value: 'table' },
              ]"
              buttons
              size="sm"
              button-variant="light"
            />
          </b-form-group>
        </div>
        <!-- card -->
        <section
          v-if="view === 'grid'"
          class="grid-view mt-1"
        >
          <b-row>
            <b-col
              v-for="(program, i) in rows"
              :key="i"
              md="4"
            >
              <b-card
                tag="article"
                header-tag="header"
                class="ecommerce-card border"
                no-body
              >
                <Promised
                  v-if="program.image"
                  :promise="getLinkFromS3(program.image)"
                >
                  <template #pending>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                  <template #default="data">
                    <b-aspect
                      :style="`background: url(${data}) center center / cover no-repeat`"
                      aspect="2"
                    />
                  </template>
                  <template #rejected>
                    <b-card-img
                      class="card-img"
                      src="https://picsum.photos/600/300"
                    />
                  </template>
                </Promised>
                <b-card-img
                  v-else

                  class="card-img"
                  src="https://picsum.photos/600/300"
                />
                <b-card-body>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                      <b-badge
                        class="mb-1"
                        variant="light-primary"
                      >
                        {{ program.type }}
                      </b-badge>
                      <b-card-title> {{ program.title }}</b-card-title>
                    </div>
                    <b-link
                      :to="{name: 'view-events', params: {id: program.id}}"
                    >
                      <b-button
                        class="ml-2"
                        size="sm"
                        variant="primary"
                        @click.stop="viewProgram(program .id)"
                      >
                        View
                      </b-button>
                    </b-link>
                  </div>
                  <div class="d-flex align-items-baseline justify-content-between">
                    <b-card-text><strong>
                      <feather-icon
                        class="mb-25"
                        icon="CalendarIcon"
                        size="18"
                      />
                      {{ $moment(program.begin_date).format("MMM DD, YYYY") }}</strong></b-card-text>
                    <b-badge
                      v-if="program.delivery_mode"
                      class="badge badge-light-success"
                    >
                      {{ program.delivery_mode }}
                    </b-badge>
                  </div>
                  <div class="item-options text-center d-flex justify-content-between">
                    <b-button-group>
                      <b-link
                        :to="{ name: 'events-application-manager', params: { id: program.id }}"
                        class="btn btn-outline-primary btn-cart"
                        size="sm"
                      >
                        <span>Applications</span>
                      </b-link>
                    </b-button-group>
                    <b-button-group>
                      <b-link
                        v-b-tooltip="'Update'"
                        class="btn btn-outline-primary btn-cart ml-1 "
                        size="sm"
                        variant="outline-primary"
                        @click="editEvent(program)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="14"
                        />
                      </b-link>
                    </b-button-group>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between align-items-center  flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLengthGrid"
                :options="['9', '27', '81']"
                class="mx-1"
                @input="updatePageLength"
              />
              <span class="text-nowrap"> of {{ rows.length }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :value="1"
                :per-page="pageLengthGrid"
                :total-rows="totalItems"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value) => onPageChange(value)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </section>
        <!-- table -->
        <vue-good-table
          v-if="view === 'table'"
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'title'">
              <div class="d-flex justify-content-between">
                <span>
                  {{ props.row.title }}
                </span>
                <b-button
                  variant="flat-primary"
                  size="sm"
                  @click="selectedRow = props.row.originalIndex; selectedId = props.row.id; newTitle = props.row.title; $bvModal.show('updateTitle')"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="14"
                  />
                </b-button>
              </div>
              <b-badge
                v-if="props.row.delivery_mode"
                class="badge badge-light-success"
              >
                {{ props.row.delivery_mode }}
              </b-badge>

            </span>
            <span v-else-if="props.column.field === 'type'">
              {{ props.row.type }}

            </span>
            <span v-else-if="props.column.field === 'date'">
              {{ formatDate(props.row.begin_date) }}
              <span> - </span>
              {{ formatDate(props.row.end_date) }}
            </span>
            <span v-else-if="props.column.field === 'total_applicants'">
              {{ props.row.programs_applicantstables_aggregate.aggregate.count }}

            </span>
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :variant="statusVariant(props.row.status)"
                class="border"
              >
                {{ props.row.status }}
              </b-badge>

            </span>

            <!-- Column: Action -->
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex"
            >
              <b-button
                class="mr-50"
                size="sm"
                variant="outline-primary"
                :to="{name: 'events-application-manager', params: {id: props.row.id}}"
              >
                Applications
              </b-button>
              <b-button
                v-b-tooltip="'Update Links'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')"
              >
                <feather-icon
                  icon="VideoIcon"
                  size="14"
                />
              </b-button>
              <b-button
                v-b-tooltip="'Update Links'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')"
              >
                <feather-icon
                  icon="PlayCircleIcon"
                  size="14"
                />
              </b-button>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card></b-overlay>
    <b-modal
      id="scheduleEventModal"
      ok-title="Invite"
      size="lg"
      :title="'Invite '+$route.params.sname+' to Event'"
      @ok="addEvent"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Select Event"
            label-for="Event"
          >
            <v-select
              id="Event"
              v-model="type"
              :options="meetingTypeOptions"
              placeholder="Select Event"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Message"
            label-for="message"
          >
            <b-form-textarea
              id="message"
              v-model="message"
              placeholder="Enter your message here"
              rows="5"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="editEventModal"
      ok-title="Update"
      title="Edit Event"
      @ok="updateEvent"
    >
      <b-form-group label="Title">
        <b-form-input v-model="newTitle" />
      </b-form-group>
      <b-form-group label="Event Link">
        <b-form-input v-model="updatedEventLink" />
      </b-form-group>
      <b-form-group label="Recording Link">
        <b-form-input v-model="updatedRecordingLink" />
      </b-form-group>
    </b-modal>
    <b-modal
      id="updateTitle"
      ok-title="Update"
      title="Update Title"
      no-close-on-backdrop
      @ok="updateTitle"
    >
      <b-form-group
        label="Event Title"
        label-for="eventTitle"
      >
        <b-form-input
          id="eventTitle"
          v-model="newTitle"
          placeholder="Enter Title"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>

import {
  BAspect,
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardImg,
  BCardText,
  BCardTitle,
  BCol,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormSelect,
  BFormTextarea,
  BLink,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { Promised } from 'vue-promised'
import { formatDate } from '@/@core/utils/utils'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCardImg,
    Promised,
    BAspect,
    BCardTitle,
    BFormRadioGroup,
    BBadge,
    BLink,
    BButtonGroup,
    BCard,
    BCol,
    BRow,
    BCardText,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      title: '',
      view: 'grid',
      pageLengthGrid: 9,
      type: '',
      beginTime: '',
      endTime: '',
      meetingLink: '',
      message: '',
      newTitle: '',
      status: '',
      mutationLoading: false,
      meetingTypeOptions: [],
      searchTerm: '',
      selectedRow: 0,
      selectedId: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Total Applicants',
          field: 'total_applicants',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
    totalItems() {
      return this.rows.length
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.pageLength)
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        scheduled: 'light-primary',
        discontinued: 'light-danger',
        draft: 'light-secondary',
        complete: 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  methods: {
    formatDate,
    editEvent(program) {
      this.selectedRow = program.originalIndex
      this.newTitle = program.title
      this.updatedEventLink = program.eventLink
      this.updatedRecordingLink = program.recordingLink
      this.$bvModal.show('editEventModal')
    },
    onPageChange(value) {
      this.currentPage = value
    },
    updateView(view) {
      this.view = view
    },
    updateTitle() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_basicinfo_by_pk(pk_columns: {id: ${this.selectedId} }, _set: {title: "${this.newTitle}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_basicinfo_by_pk } }) => {
          if (update_programs_basicinfo_by_pk.id) this.showAlert('Title updated successfully', 'success')
          else this.showAlert('Failed to update title', 'danger')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    addEvent() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation insertSchedule($beginTime: timestamptz!, $endTime: timestamptz!, $meetingLink: String!, $title: String!, $type: String!, $associationId: Int!) {
          insert_programs_scheduletable_one(object: {begin_time: $beginTime, end_time: $endTime, meeting_link: $meetingLink, status: "scheduled", title: $title, type: $type, programs_meetparticipanttables: {data: {user_association_id: $associationId}}}) {
            id
          }
        }`,
        variables: {
          beginTime: this.beginTime,
          endTime: this.endTime,
          meetingLink: this.meetingLink,
          title: this.title,
          type: this.type,
          associationId: this.selectedOrg.associationId,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'EMeet created successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to create EMeet',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_programs_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_programs_scheduletable_by_pk } }) => {
          if (update_programs_scheduletable_by_pk.id) this.showAlert('Links updated successfully', 'success')
          else this.showAlert('Failed to update links', 'danger')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables() {
          return {
            objects: this.rows[this.selectedRow].programs_meetparticipanttables,
          }
        },
        update: () => {
          this.showAlert('Participants updated successfully', 'success')
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
          query EventsByIncubator($incubatorId: Int!){
          programs_basicinfo(order_by: {date_created: desc}, where: {organization_id_id: {_eq: $incubatorId}, is_event: {_eq: true}}) {
            id,
            title,
            status,
            programs_applicantstables_aggregate {
              aggregate {
                count
              }
            },
            type,
            begin_date,
            capacity,
            city,
            customer_type,
            delivery_mode,
            description,
            duration,
            end_date,
            focus_type,
            offerings_type,
            ideal_lifecycle_stage,
            industry,
            image,
            is_public,
            offerings_type,
            state
          }
        }`
      },
      variables() {
        return {
          incubatorId: this.selectedOrg.orgId,
        }
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
